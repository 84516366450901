// rate type
const EN_ROUTE_PLUS_HOURLY = 'EnRoutePlusHourlyRate'
const HOURLY_P2P = 'HoursP2PRate'
const MILEAGE_TOWED = 'MilesTowedRate'
const MILEAGE_EN_ROUTE = 'MilesEnRouteRate'
const MILEAGE_EN_ROUTE_ONLY = 'MilesEnRouteOnlyRate'
const MILEAGE_P2P = 'MilesP2PRate'
const FLAT = 'FlatRate'
const STORAGE = 'StorageRate'
const TESLA_RATE = 'TeslaRate'

// job status
const CREATED = 'Created'
const PREDRAFT = 'Predraft'
const DRAFT = 'Draft'
const PENDING = 'Pending'
const UNASSIGNED = 'Unassigned'
const ASSIGNED = 'Assigned'
const AUTO_ASSIGNING = 'Auto Assigning'
const ACCEPTED = 'Accepted'
const ETAEXTENDED = 'ETA Extended'
const REJECTED = 'Rejected'
const REASSIGN = 'Reassign'
const DISPATCHED = 'Dispatched'
const ENROUTE = 'En Route'
const SCHEDULED_FOR = 'Scheduled For'
const ONSITE = 'On Site'
const TOWING = 'Towing'
const TOWDESTINATION = 'Tow Destination'
const STORED = 'Stored'
const SUBMITTED = 'Submitted'
const COMPLETED = 'Completed'
const UNSUCCESSFUL = 'Unsuccessful'
const RELEASED = 'Released'
const PAYMENT_PENDING = 'Payment Pending'
const PROVIDER_RELEASED = 'Provider Released'
const CANCELED = 'Canceled'
const GOA = 'GOA'
const REASSIGNED = 'Reassigned'
const REASSIGN_REQUESTED = 'Reassign Requested'
const GOA_REQUESTED = 'GOA Requested'
const ETAREJECTED = 'ETA Rejected'
const EXPIRED = 'Expired'
const DELETED = 'Deleted'
const UNSUCCESSFUL_SERVICE_REQUESTED = 'Unsuccessful Requested'
const UNPAID = 'Unpaid'

// client names
const GEICO = 'Geico'
const ALLSTATE = 'Allstate'
const AGERO = 'Agero'
const ALLIED = 'Allied Dispatch Solutions'
const NATIONSAFE = 'Nation Safe Drivers'
const ROADAMERICA = 'Road America'
const QUEST = 'Quest'
const USAC = 'USAC'
const TESLA_MOTORS = 'Tesla Motors Inc'

if (typeof window.ENV !== 'object') {
  // @ts-expect-error
  window.ENV = {}
}

const Consts = {
  SITE_NAME: 'Swoop',
  SWOOP_ENV: window.ENV.SWOOP_ENV,
  SPLIT_KEY: window.ENV.SPLIT_KEY || null,
  SPLIT_TIMEOUT: window.ENV.SPLIT_TIMEOUT || 30,
  GOOGLE_ANALYTICS_KEY: window.ENV.GOOGLE_ANALYTICS_KEY,
  CMW_REVERSE_GEOCODING_DEBOUNCE: window.ENV.CMW_REVERSE_GEOCODING_DEBOUNCE,
  DATADOG_CLIENT_TOKEN: window.ENV.DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_CLIENT_TOKEN: window.ENV.DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_RUM_APPLICATION_ID: window.ENV.DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_SAMPLE_RATE: parseInt(window.ENV.DATADOG_RUM_SAMPLE_RATE || '60', 10),
  DATADOG_RUM_ENV: window.ENV.DATADOG_RUM_ENV,
  DATADOG_ENV: window.ENV.DATADOG_ENV,
  EXTENDED_ETA_RANGE_VALUE: 30,
  EXTENDED_ETA_RANGE_EXPERIMENT_VALUE: 15,
  SCREEN_POP_FALLBACK_TIMEOUT: window.ENV.SCREEN_POP_FALLBACK_TIMEOUT || 30000,
  SHOW_SESSION_TIMEOUT_MODAL_IN_SECONDS:
    window.ENV.SHOW_SESSION_TIMEOUT_MODAL_IN_SECONDS || 29 * 60,
  INACTIVITY_REFRESH_MINUTES: window.ENV.INACTIVITY_REFRESH_MINUTES || 120,
  SSO_FALLBACK_ENABLED: window.ENV.SSO_FALLBACK_ENABLED === 'true',
  SESSION_REFRESH_TIMEOUT_MINUTES: window.ENV.SESSION_REFRESH_TIMEOUT_MINUTES || 5,
  CONFIRM_SESSION_TIMEOUT_MODAL_IN_MINUTES:
    window.ENV.CONFIRM_SESSION_TIMEOUT_MODAL_IN_MINUTES || 1,
  VCC_APPROVAL_THRESHOLD: Number.parseInt(window.ENV.VCC_APPROVAL_THRESHOLD, 10) || 500,
  WALK_ME_FOR_SWOOP_SRC: window.ENV.WALK_ME_FOR_SWOOP_SRC,
  ISSUES_CLAIM_VALUE: '5',
  ISSUES_DAMAGE_VALUE: '34',
  MAX_SEARCH_TERM_SIZE: window.ENV.MAX_SEARCH_TERM_SIZE || '150',
  SPLIT_CABLE_HANDLER_TIMEOUT: window.ENV.SPLIT_CABLE_HANDLER_TIMEOUT || 5000,
  SPLIT_EXPERIMENT_VARIANT_ON: 'on',
  SPLIT_EXPERIMENT_VARIANT_OFF: 'off',
  DASHBOARD_HIDE_TIMEOUT: window.ENV.DASHBOARD_HIDE_TIMEOUT || 60000,
  BE_SPLITS: {
    ENABLE_AM_VCC_PAYMENTS: 'ENG-54910-AM_VCC_Payments',
    UNSUCCESSFUL_WORKFLOW_V2: 'ENG-65005-Unsuccessful-Workflow-V2',
    // This is actually hardcoded in codebase to preserve @mask directive on screenpops, keeping here for reference
    ENABLE_SECONDARY_CTA_MISSING_ALERT_SCREENPOP:
      'ENG-69443-Missing-Information-Required-Alert-Secondary-CTA-Screenpop',
    // This is actually hardcoded in codebase to preserve @mask directive on screenpops, keeping here for reference
    ENABLE_SECONDARY_CTA_COMPLETE_CONFIRMATION_ALERT_SCREENPOP:
      'ENG-70277-Complete-Confirmation-Alert-Secondary-CTA-Screenpop',
  },
  SPLITS: {
    ENABLE_PROVIDER_BACKGROUND_CHECKS: 'ENG-71573-user-level-provider-background-checks',
    DISABLE_BACKGROUND_FETCHES: 'ENG-29722-Disable-Background-Fetches',
    DISABLE_DASHBOARD_INTERVAL_UPDATE: 'disable_DASHBOARD_INTERVAL_UPDATE_ENG-23097',
    DISABLE_WS_OBJECTS: 'ENG-31836-Disable-WS-Objects',
    DISABLE_OLD_WEBSOCKET_CONNECTION: 'ENG-69643-disable-old-websocket-connection',
    ENABLE_ACTION_CABLE_PRO: 'ENG-30347-actioncable-pro-subscriptions',
    ENABLE_APPLICATION_WRAPPER_MIGRATION: 'ENG-58651-enable-application-wrapper-migration',
    ENABLE_CCAAS_MULTI_PLATFORM_LOGIN: 'ENG-71303-CCaaS-Integration',
    ENABLE_CMW_VERTICAL_ACTIONS_MENU: 'ENG-72521-cmw-enable-vertical-more-actions-menu',
    ENABLE_COLLAPSIBLE_CONTACT_FORM_HANDLING:
      'ENG-46709-collapsible-pickup-dropoff-contact-form-handling',
    ENABLE_FORCE_BROWSER_REFRESH: 'ENG-47739-enable-force-browser-refresh-tool',
    ENABLE_GRAPHQL_ASSIGN_DRIVER_MODAL: 'ENG-58878-enable-graphql-assign-driver-modal',
    ENABLE_GRAPHQL_RETRY: 'ENG-52706-graphql-retry',
    ENABLE_GQL_ASYNC_RESPONSES: 'ENG-26675-enable-gql-async-responses',
    ENABLE_GQL_JOB_UPDATES: 'ENG-28191-enable-gql-job-updates',
    ENABLE_JOB_HISTORY_TAB_ASCENDING_SORT: 'ENG-31324-job-history-item-ascending-sort',
    ENABLE_NEW_ASYNC_REQUEST_MECHANISM: 'ENG-66307-enable-new-async-request-mechanism',
    ENABLE_MIGRATED_GEOSUGGEST_COMPONENTS: 'ENG-41002-enable-migrated-geosuggest-components',
    ENABLE_MWI_CUSTOMER_RATE_ASYNC_REQUEST: 'ENG-66420-enable-mwi-customer-rate-async-request',
    ENABLE_MWI_MANUAL_ENTRY: 'ENG-58015-enable-mwi-manual-entry',
    ENABLE_OPTIMIZED_JOBS_QUERY: 'ENG-65018-enable-optimized-jobs-query',
    ENABLE_PONG_LOGGER: 'ENG-27303-enable-pong-logger',
    ENABLE_PONG_RECONNECT: 'ENG-27303-enable-pong-reconnect',
    ENABLE_PONG_SYNC: 'ENG-27303-enable-pong-sync',
    ENABLE_RE_REFACTOR_P2: 'ENG-60051-required-equipment-refactor-part-2',
    ENABLE_REACT_STRICT_MODE: 'ENG-50749-enable-react-strict-mode',
    ENABLE_REDIS_WEBSOCKET_URL: 'ENG-26770_enable_redis_websocket_url',
    ENABLE_WALKME_INTEGRATION_TOOL: 'ENG-34199-enable-walkme-integration-tool',
    ENABLE_GRAPHQL_TRUCK_ASSIGNED_FIELD: 'ENG-44553-enable-graphql-job-form-truck-assigned-field',
    ENALBE_USER_GQL_SPLIT: 'ENG-38079-disable_ws_users_updates',
    MANUAL_ASSIGN_MODAL_CLEANUP: 'ENG-40652-manual-assign-modal-cleanup',
    PROFILE_LOOKUP_SKIPABLE: 'ENG-57669-profile-lookup-skipable',
    REACT_HOOK_USER_FORM_FIELD: 'ENG-37129-enable-react-hook-form-field-in-user-form',
    SHOW_COVERAGE_RESULT_DEBUG_TOOLTIP: 'ENG-29010-show-coverage-result-debug-tooltip',
    ENABLE_USER_ACCESS_RESTRICTIONS: 'ENG-51624_User_Access_Restrictions',
    ENABLE_NEW_JOB_FORM: 'ENG-70105-enable-new-job-form',
    EXCLUDE_DOCUMENTS_FROM_INVOICES: 'ENG-61470-exclude-documents-from-invoices',
    ENABLE_COVERAGE_AUTHORIZATION_FORM_GRAPHQL:
      'ENG-65066-enable-coverage-authorization-form-graphql',
    ENABLE_MWI_CUSTOMER_PAYMENT_SUBSCRIPTION: 'ENG-66134-enable-mwi-customer-payment-subscription',
    ENABLE_INVOICE_EDITING: 'ENG-66327-enable-invoice-editing',
    ENABLE_AUTHORIZATION_COVERAGE_LIMITS: 'ENG-52007-authorization-coverage-limits',
    ENABLE_SESSION_TIMEOUT: 'ENG-59254-enable-session-timeout',
    ENABLE_RELEASE_AUTO_CREATE_TOW: 'ENG-71591-release-auto-create-tow',
    ENABLE_NEW_RELEASE_FEES_EDITING: 'ENG-67449-enable-new-release-fees-editing',
    ENABLE_MWI_PROGRESS_TRACKER: 'ENG-70999-enable-mwi-progress-tracker',
    ENABLE_MWI_BLOCK_CUSTOMER_NOT_WITH_VEHICLE:
      'ENG-71928-enable-block-if-customer-not-with-vehicle',
    HIDE_LEGACY_INVOICE_MODAL: 'ENG-72520-hide-legacy-InvoiceModal',
    SHOW_NEW_CANCEL_JOB_MODAL: 'ENG-72327-FE_Cancel_Job_Modal_Refactor',
    ENABLE_RESPONSIVE_TABLES: 'ENG-73309-enable-responsive-tables',
    ENABLE_ETA_RANGE_EXPERIMENT: 'ENG-72610-ETA-Range-Experiment',
  },

  SURVEY_TEXT_DELAY_THRESHOLD_VAR: 'survey_text_delay_threshold',
  AUTOCOMPLETE_JOB_THRESHOLD_VAR: 'autocomplete_job_threshold',
  REDISPATCH_REFERRED_JOB_THRESHOLD_VAR: 'redispatch_referred_job_threshold',

  LOGIN_FLASH_ERROR_MESSAGE_KEY: 'loginFlashErrorMessage',

  ENV_VAR: {
    CONTACT_NUMBER: 'CONTACT_NUMBER',
    CUSTOM_SUPPORT_CTA: 'CUSTOM_SUPPORT_CTA',
    FEEDBACK_EMAIL: 'FEEDBACK_EMAIL',
    HELP_CENTER_URL: 'HELP_CENTER_URL',
    ONBOARDING_TERMS_URL: 'ONBOARDING_TERMS_URL',
    POLICY_NUMBER_PARTIAL_SEARCH_MIN_CHARS: 'POLICY_NUMBER_PARTIAL_SEARCH_MIN_CHARS',
    PRIVACY_URL: 'PRIVACY_URL',
    SUPPORT_CONTACT: 'SUPPORT_CONTACT',
    SUPPORT_CONTACT_EMAIL: 'SUPPORT_CONTACT_EMAIL',
    SUPPORT_PHONE_NUMBER: 'SUPPORT_PHONE_NUMBER',
    TERMS_URL: 'TERMS_URL',
    SWOOP_API_URL: 'SWOOP_API_URL',
  },

  // See file_uploader.rb
  UPLOADABLE_MIME_TYPES: {
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.ms-xpsdocument': ['.xps'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.doc', '.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.doc', '.docx'],
    'audio/*': ['.mp3', '.mpeg', '.wav'],
    'image/*': ['.bmp', '.gif', '.heic', '.jpeg', '.jpg', '.png', '.tiff'],
    'text/*': ['.csv', '.rtf', '.txt'],
    'video/*': ['.avi', '.mp4', '.mpeg'],
  },

  ROOT_PATH: '',
  API_PATH: '/api/v1/',
  LOGIN_PATH: 'login',
  WS_ASYNC_REQUEST_TIMEOUT: window.ENV.WS_ASYNC_REQUEST_TIMEOUT || 15000,
  WS_ASYNC_REQUEST_TIMEOUT_PLS: window.ENV.WS_ASYNC_REQUEST_TIMEOUT_PLS || 15000,
  WS_COVERAGE_LOOKUP_FALLBACK_TIMEOUT: window.ENV.WS_COVERAGE_LOOKUP_FALLBACK_TIMEOUT || 10000,
  SUBSCRIPTION_DEBOUNCED_TIMEOUT: window.ENV.SUBSCRIPTION_DEBOUNCED_TIMEOUT || 3000,
  MAX_RESULT_SET: 10000,
  // job status
  CREATED,
  PREDRAFT,
  DRAFT,
  PENDING,
  UNASSIGNED,
  ASSIGNED,
  AUTO_ASSIGNING,
  ACCEPTED,
  ETAEXTENDED,
  REJECTED,
  REASSIGN,
  DISPATCHED,
  ENROUTE,
  SCHEDULED_FOR,
  ONSITE,
  TOWING,
  TOWDESTINATION,
  STORED,
  SUBMITTED,
  COMPLETED,
  UNSUCCESSFUL,
  RELEASED,
  PAYMENT_PENDING,
  PROVIDER_RELEASED,
  CANCELED,
  GOA,
  REASSIGNED,
  REASSIGN_REQUESTED,
  GOA_REQUESTED,
  ETAREJECTED,
  EXPIRED,
  DELETED,
  UNSUCCESSFUL_SERVICE_REQUESTED,
  UNPAID,

  DISPATCHED_STATUSES_AND_ASSIGNED: [ASSIGNED, DISPATCHED, ENROUTE, ONSITE, TOWING, TOWDESTINATION],

  // not a status; a string to display
  SCHEDULED_JOB: 'Scheduled Job ',
  // auto_dispatch status
  AUCTION_LIVE: 'live',
  AUCTION_SUCCESSFUL: 'successful',
  AUCTION_UNSUCCESSFUL: 'unsuccessful',
  AUCTION_CANCELED: 'canceled',
  AUCTION_INELIGIBLE: 'ineligible',
  AUCTION_SCHEDULED: 'scheduled',

  FLEET_IN_HOUSE_JOB: 'FleetInHouseJob',
  // NOT A REAL STATUS
  SCHEDULED: 'Scheduled',
  MISSING_PHONE: 'x',
  ERROR: 'error',
  CRITICAL: 'critical',
  WARNING: 'warning',
  EMERGENCY: 'emergency',
  INFO: 'info',
  HELP_INFO: 'help-info',
  SUCCESS: 'success',
  SUCCESS_PHONE: 'phone-success',
  ERROR_PHONE: 'phone-error',
  SECRET_FIELD_DOTS: '•••••',

  // notifications
  NOTIFICATION_TYPES: {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
  },

  EN_ROUTE_PLUS_HOURLY,
  HOURLY_P2P,
  MILEAGE_TOWED,
  MILEAGE_EN_ROUTE,
  MILEAGE_EN_ROUTE_ONLY,
  MILEAGE_P2P,
  FLAT,
  STORAGE,
  TESLA_RATE,

  RATE_TYPE_MAP: {
    [EN_ROUTE_PLUS_HOURLY]: 'En Route + Hourly',

    [FLAT]: 'Flat',

    [MILEAGE_TOWED]: 'Mileage (Towed)',
    [MILEAGE_EN_ROUTE_ONLY]: 'Mileage (En Route)',
    [MILEAGE_EN_ROUTE]: 'Mileage (En Route + Towed)',
    [MILEAGE_P2P]: 'Mileage (Port to Port)',

    [HOURLY_P2P]: 'Hourly (Port to Port)',

    [STORAGE]: 'Storage',
    [TESLA_RATE]: 'Tesla',
  } as const,

  FLAT_RATE_FIELD: {
    LABEL: 'Flat Rate',
    NAME: 'flat_rate',
  },
  SERVICE_FEE_FIELD: {
    LABEL: 'Service Fee',
    NAME: 'service_fee',
  },
  EN_ROUTE_PER_MILE_FIELD: {
    LABEL: 'En Route Per Mile',
    NAME: 'en_route_per_mile',
  },
  TOWED_PER_MILE_FIELD: {
    LABEL: 'Towed Per Mile',
    NAME: 'towed_per_mile',
  },
  HOURLY_RATE_FIELD: {
    LABEL: 'Hourly Rate',
    NAME: 'hourly_rate',
  },
  DEADHEAD_PER_MILE_FIELD: {
    LABEL: 'Deadhead Per Mile',
    NAME: 'deadhead_per_mile',
  },
  PARTIAL_FEE_FIELD: {
    LABEL: 'Partial Fee',
    NAME: 'partial_fee',
  },

  EN_ROUTE_FREE_MILES: {
    LABEL: 'En Route Free Miles',
    NAME: 'en_route_free_miles',
  },
  TOWED_FREE_MILES: {
    LABEL: 'Towed Free Miles',
    NAME: 'towed_free_miles',
  },
  DEADHEAD_FREE_MILES: {
    LABEL: 'Deadhead Free Miles',
    NAME: 'deadhead_free_miles',
  },
  EFFECTIVE_DATE: {
    LABEL: 'Effective Date',
    NAME: 'effective_date',
  },

  VEHICLE_TYPES: {
    PASSENGER_CAR: 'Passenger Car',
    MULTIPURPOSE_PASSENGER_VEHICLE: 'Multipurpose Passenger Vehicle',
    PICKUP_TRUCK: 'Pickup Truck',
    VAN: 'Van',
    RECREATIONAL_VEHICLE: 'Recreational Vehicle',
    MOTORCYCLE: 'Motorcycle',
    TRUCK: 'Truck',
    TRAILER: 'Trailer',
    ANTIQUE: 'Antique',
    BUS: 'Bus',
    BICYCLE: 'Bicycle',
    GOLF_CART: 'Golf Cart',
    INCOMPLETE_VEHICLE: 'Incomplete Vehicle',
    SNOWMOBILE: 'Snowmobile',
  },
  ALL_SYMPTOMS: 'All Symptoms',
  ALL_SERVICES: 'All Services',
  ANY: 'ANY',
  // Service Types
  ACCIDENT_TOW: 'Accident Tow',
  ACCIDENT_TOW_P: 'Accident Tow (P)',
  ASAP_TRANSPORT: 'ASAP Transport',
  BATTERYJUMP: 'Battery Jump',
  DECKING: 'Decking',
  HEAVYHAUL: 'Heavy Haul',
  IMPOUND: 'Impound',
  LAW_ENFORCEMENT: 'Law Enforcement',
  LOCKOUT: 'Lock Out',
  FUEL_DELIVERY: 'Fuel Delivery',
  RECOVERY: 'Recovery',
  REUNITE: 'Reunite',
  SECONDARYTOW: 'Secondary Tow',
  SERVICE_STORAGE: 'Storage',
  TIRE_CHANGE: 'Tire Change',
  TOW: 'Tow',
  TRANSPORT: 'Transport',
  UNDECKING: 'Undecking',
  WINCHOUT: 'Winch Out',
  REPO: 'Repo',
  SERVICE_RELEASE: 'Release',
  // Invoice statuses
  ICLOSED: 'closed',
  ICREATED: 'created',
  IPARTNERNEW: 'partner_new',
  IPARTNERAPPROVED: 'partner_approved',
  IPARTNERSENT: 'partner_sent',
  IPARTNERUNDOAPPROVAL: 'partner_undo_approval',
  IPARTNERMOVEBACKTONEW: 'partner_move_back_to_new',
  IMOVEBACKTOPARTNERNEW: 'move_back_to_partner_new',
  IPARTNERMARKSENT: 'partner_mark_sent',
  IFLEETAPPROVED: 'fleet_approved',
  IFLEETDOWNLOADED: 'fleet_downloaded',
  IFLEETUNDOAPPROVAL: 'fleet_undo_approval',
  IFLEETMOVEBACKFROMDOWNLOADED: 'fleet_move_back_from_downloaded',
  IFLEETREJECTED: 'fleet_rejected',
  IPARTNEROFFLINENEW: 'partner_offline_new',
  ISWOOPNEW: 'swoop_new',
  // ISWOOPAPPROVED: "swoop_approved"
  // ISWOOPSENT: "swoop_sent"
  ISWOOPSENDINGFLEET: 'swoop_sending_fleet',
  ISWOOPSENTFLEET: 'swoop_sent_fleet',
  ISWOOPDOWNLOADEDPARTNER: 'swoop_downloaded_partner',
  ISWOOPUNDOAPPROVALPARTNER: 'swoop_undo_approval_partner',
  ISWOOPUNDOAPPROVALFLEET: 'swoop_undo_approval_fleet',
  ISWOOPAPPROVEDFLEET: 'swoop_approved_fleet',
  ISWOOPAPPROVEDPARTNER: 'swoop_approved_partner',
  ISWOOPREJECTEDPARTNER: 'swoop_rejected_partner',
  IONSITEPAYMENT: 'on_site_payment',
  INVOICE: {
    ALREADY_BEEN_SENT:
      'This invoice has already been sent. Are you sure you want to move it back to the New tab?',
    CAN_BE_EDITED_AGAIN: 'It can be edited and sent again once moved back.',

    FLEET_OR_SWOOP_HAS_ALREADY_RECEIVED(recipient_name: string) {
      return `${recipient_name} has already received this invoice. Are you sure you want to move it back to the New tab?`
    },

    FLEET_OR_SWOOP_HAS_ALREADY_APPROVED(recipient_name: string) {
      return `This invoice cannot be moved back as ${recipient_name} has already approved it.`
    },

    FLEET_OR_SWOOP_HAS_ALREADY_PAID(recipient_name: string) {
      return `This invoice cannot be moved back as ${recipient_name} has already sent a payment for it.`
    },

    CONTACT_FLEET_OR_SWOOP(recipient_name: string) {
      return `If you need to make changes to it, please contact ${recipient_name} and ask them to undo the approval of the invoice.`
    },

    REMOVE_FROM_APPROVAL_QUEUE:
      'It will be removed from their approval queue, and it can be edited and sent again once moved back.',
    UNDO_APPROVAL_WARNING:
      'An associated invoice for a Client Managed account has already been approved. Unapproving this invoice will delete the Client Managed invoice. Would you like to proceed?',
    IT_CAN_BE_EDITED_AGAIN: 'It can be edited and approved again once moved back.',
    ALREADY_BEEN_DOWNLOADED:
      'This invoice has already been downloaded. Are you sure you want to move it back to the Approved tab?',
    WAS_PAID_ON_SITE: 'This invoice cannot be moved back as it has already been paid for on site.',
    ARE_YOU_SURE_TO_MARK_UNPAID:
      'This invoice was paid on site. Are you sure you want to mark unpaid and move it back to the New tab?',
  },
  INVOICE_REJECTION: {
    CONFIRMATION:
      'Are you sure you wish to reject this invoice? You will still retain a record of the job in the Done tab and the partner will receive an email notifying them.',
  },
  JOBS_PER_PAGE_COUNT: window.ENV.JOBS_PER_PAGE_COUNT || 12,
  MAX_ACTIVE_JOB_LOAD_COUNT: window.ENV.MAX_ACTIVE_JOB_LOAD_COUNT || 1000,
  MAX_JOB_BATCH_SIZE: window.ENV.MAX_JOB_BATCH_SIZE || 12,
  DEFAULT_PAGE_SIZE: 50,
  DEFAULT_GRAPHQL_PAGE_SIZE: 20,
  PARTNER_PAGE_SIZE: 50,
  N_TIME: 10000,
  SECONDS_BEFORE_SHOWING_WEBSOCKET_ERRORS: 30,
  JS_ENV: '',
  SWOOP: 'Swoop',
  TESLA: 'Tesla',
  GEICO,
  AGERO,
  ALLSTATE,
  ALLIED,
  NATIONSAFE,
  TESLA_MOTORS,
  ROADAMERICA,
  QUEST,
  USAC,
  MOTORCLUBS: [GEICO, AGERO, ALLSTATE, ALLIED, NATIONSAFE, TESLA_MOTORS, ROADAMERICA, QUEST, USAC],
  ENTERPRISE_FLEET_MANAGEMENT: 'Enterprise Fleet Management',
  FARMERS: 'Farmers Insurance',
  METROMILE: 'Metromile',
  LINCOLN: 'Lincoln',
  INFINITI: 'INFINITI',
  CENTURY: '21st Century Insurance',
  FLEET_RESPONSE: 'Fleet Response',
  STATES: [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District Of Columbia',
    'Federated States Of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Islands',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  STATE_ABREVS: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  CASHCALL: 'Cash Call',
  CUSTOMERPAY: 'Customer Pay',
  ANSWERS_YES: 'Yes',
  ANSWERS_NO: 'No',
  QUESTION_KEY_LOCKED_IN: 'locked-in',
  QUESTION_CUSTOMER_SAFE: 'Customer in a safe location?',
  QUESTION_ALTERNATE_RIDE_REQUESTED: 'Alternate ride requested?',
  QUESTION_HAS_UNLISTED_TRAILER: 'Has Trailer?',
  QUESTION_LOW_CLEARANCE: 'Low clearance? (Below 7ft)',
  QUESTION_SPARE_TIRE_IN_GOOD_CONDITION: 'Have a spare in good condition?',
  QUESTION_VEHICLE_4_WHEEL_DRIVE: 'Vehicle 4 wheel drive?',
  DISTANCE_ROUND_NEAREST_TENTH: 'nearest_tenth',
  DISTANCE_ROUND_NEAREST: 'nearest',
  DISTANCE_ROUND_UP: 'up',
  ADDITION_LOANER_WHEEL: 'Loaner Wheel Pickup',
  ADDITION_PRIORITY_RESPONSE: 'Priority Response',
  ADDITION_TOLL: 'Toll',
  ADDITION_OVERNIGHT_STORAGE: 'Overnight Storage',
  ADDITION_REIMBURSEMENT: 'Reimbursement',
  ADDITION_DOLLY: 'Dolly',
  ADDITION_GOJAK: 'GoJak',
  ADDITION_TIRE_SKATES: 'Tire Skates',
  ADDITION_WAIT_TIME: 'Wait Time',
  CUSTOMER_TYPE_WARRANTY: 'Warranty',
  CUSTOMER_TYPE_GOODWILL: 'Goodwill',
  CUSTOMER_TYPE_CUSTOMER_PAY: 'Customer Pay',
  CUSTOMER_TYPE_PCARD: 'P Card',
  CUSTOMER_TYPE_MECHANICAL: 'Mechanical',
  CUSTOMER_TYPE_SERVICE_CENTER: 'Service Center',
  CUSTOMER_TYPE_AUCTION: 'Auction',
  CUSTOMER_TYPE_DAMAGE: 'Claim/Damage',
  CUSTOMER_TYPE_BODY_SHOP: 'Body Shop',
  CUSTOMER_TYPE_TAX_EXEMPT: 'Tax Exempt',
  CUSTOMER_TYPE_BRANCH: 'Branch',
  PHONE_STATUS_OFFLINE: 'offline',
  PHONE_STATUS_AVAILABLE: 'available',
  PHONE_STATUS_BREAK: 'break',
  PHONE_STATUS_LUNCH: 'lunch',
  PHONE_STATUS_BUSY: 'busy',
  FEATURE_MFA: 'Multi-Factor Authentication',
  FEATURE_SINGLE_SIGN_ON: 'Single Sign On',
  FEATURE_CLAIM_NUMBER: 'Job Form Claim Number',
  FEATURES_CUSTOMER_PAYMENT_COLLECTION: 'Customer Payment Collection',
  FEATURES_CREDIT_CARD_COLLECTION: 'Credit Card Collection',
  FEATURES_TELEPHONY_INTEGRATION: 'Telephony Integration',
  FEATURES_AUTO_SELECT_SITE_FROM_LOOKUP: 'Auto Select Site from Lookup',
  FEATURES_JOB_FORM_COPY_PROFILE_PHONE_NUMBER: 'Job Form Copy Profile Phone Number',
  FEATURES_JOB_FORM_POLICY_NUMBER: 'Job Form Policy Number',
  FEATURES_JOB_FORM_EMAIL_FIELD: 'Job Form Email Field',
  FEATURES_JOB_FORM_LIMITED_CLIENT_AUTHORIZATIONS:
    'Job Form Client Authorizations for RV and Motorcycle',
  FEATURES_JOB_PO_NUMBER: 'Job PO Number',
  FEATURES_JOB_FORM_LICENSE_PLATE_FIELD_REQUIRED: 'Job Form License Plate Field Required',
  FEATURES_JOB_FORM_PO_NUMBER_REQUIRED: 'Job Form PO Number Required',
  FEATURES_JOB_FORM_SITE_REQUIRED: 'Job Form Site Required',
  FEATURE_JOB_FORM_AUTHORIZE_REQUEST_HIDDEN_FOR_PRE_DRAFT:
    'Job Form Authorize Request hidden for Pre-Draft',
  FEATURES_JOB_FORM_POLICY_NUMBER_PARTIAL_SEARCH: 'Job Form Policy Number Partial Search',
  FEATURES_JOB_FORM_POLICY_NUMBER_REQUIRED: 'Job Form Policy Number Required',
  FEATURES_JOB_FORM_UNIT_REQUIRED: 'Job Form Unit Required',
  FEATURES_JOB_FORM_ODOMETER_REQUIRED: 'Job Form Odometer Required',
  FEATURES_JOB_FORM_CLIENT_COMPANY_USER_CLIENT_AUTHORIZATION:
    'Job Form Enable Client Company User Client Authorization',
  FEATURES_JOB_FORM_VALID_VIN_CLIENT_AUTHORIZATIONS:
    'Job Form Valid VIN Required for Client Authorizations',
  FEATURES_JOB_FORM_VALID_VIN_SUPERVISOR_AUTHORIZATIONS:
    'Job Form Valid VIN Required for Supervisor Authorizations',
  FEATURES_JOB_FORM_VALID_POLICY_NUMBER_CLIENT_AUTHORIZATIONS:
    'Job Form Policy # Required for Client Authorizations',
  FEATURES_JOB_FORM_VALID_SERVICE_CONTRACT_NUMBER_CLIENT_AUTHORIZATIONS:
    'Job Form Service Contract # Required for Authorizations',
  FEATURES_JOB_FORM_CLIENT_AUTHORIZATION_FOR_PARTIALLY_COVERED:
    'Job Form Client Authorization for Partially Covered',
  FEATURES_SERIAL: 'Serial Number',
  FEATURES_DEPARTMENTS: 'Departments',
  FEATURES_FLEET_SITES: 'Client Sites',
  FEATURES_PAYMENT_TYPE: 'Payment Type',
  FEATURE_ENABLE_AUTO_DISPATCH_TO_TRUCK: 'Partner Dispatch to Truck',
  FEATURES_SMS_REVIEWS: 'SMS_Reviews',
  FEATURES_REVIEW_NPS_SURVEY: 'Review NPS Mobile Web',
  FEATURES_UNIT_NUMBER: 'Job Unit Number',
  FEATURES_REF_NUMBER: 'Job Reference Number',
  FEATURES_ADD_PARTNER_COLUMN_TO_FLEET_MANAGED: 'Add Partner Column to Fleet Managed',
  FEATURES_QUESTIONS: 'Questions',
  FEATURES_STORAGE_MANAGEMENT: 'Storage Management',
  FEATURES_NOT_REQUIRE_CUSTOMER_INFO: "Don't require customer name and phone",
  FEATURE_JOB_FORM_CLIENT_AUTHORIZATIONS: 'Job Form Client Authorizations',
  FEATURE_JOB_FORM_SUPERVISOR_AUTHORIZATIONS: 'Job Form Supervisor Authorizations',
  FEATURE_JOB_FORM_CUSTOMER_LOOKUP: 'Job Form Customer Lookup',
  FEATURE_JOB_FORM_COVERAGE: 'Job Form Coverage',
  FEATURE_JOB_FORM_PROGRAM_SELECTION: 'Job Form Program Selection',
  FEATURE_JOB_FORM_RECOMMENDED_DROPOFF: 'Job Form Recommended Drop Off',
  FEATURE_JOB_FORM_RESTRICTED_SUPERVISOR_AUTHORIZATION_REASONS:
    'Job Form Restricted Supervisor Authorization Reasons',
  FEATURE_JOB_FORM_DOWNTIME_PROGRAM_HANDLING: 'Job Form Downtime Program Handling',
  FEATURE_SYMPTOMS: 'Show Symptoms',
  FEATURE_HIDE_TIMESTAMPS_FOR_ACCOUNT: 'Remove invoice timestamps per account',
  FEATURE_HIDE_REPORTING: 'Hide Reporting',
  FEATURE_INVOICE_CUSTOM_ITEMS: 'Invoice Custom Items',
  FEATURE_INVOICING: 'Invoicing',
  FEATURES_SHOW_CLIENT_SITES_TO_SWOOP: 'Show Client Sites to Swoop',
  FEATURE_REVIEW_FEED: 'Review Feed',
  FEATURE_REVIEW_FEED_Q1_FILTER: 'Reviews Feed Q1 Filter',
  FEATURE_MEMBER_NUMBER: 'Job Member Number',
  FEATURE_MEMBER_NUMBER_REQUIRED: 'Job Member Number Required',
  FEATURE_DISABLE_LICENSE_RESTRICTIONS: 'Disable License Restrictions',
  FEATURE_INTERNATIONAL_PHONE_NUMBERS: 'International Phone Numbers',
  CUSTOMER: 'Customer',
  FEATURE_JOB_PRIORITY_RESPONSE_PROFILE_AUTO_SELECT: 'Job Priority Response Profile Auto Select',
  FEATURE_WINBACK: 'Post-NPS Winback',
  FEATURE_ACCIDENT_TOW_TRANSFER: 'Accident Tow Transfer',
  FEATURE_UNLISTED_VEHICLE_OWNER_AND_PASSENGER_QUESTION:
    'Unlisted Vehicle Owner & Passenger Question',
  FEATURE_PREVENT_JOB_DISPATCH: 'Prevent Job Dispatch',
  FEATURE_PRIORITY_RESPONSE: 'Priority Response',
  FEATURES_DISABLE_USER_EMAIL_NOTIFICATIONS: 'Disable user email notifications',
  FEATURE_PROVIDER_INVOICES_RESTRICT_ADDONS: 'Provider Invoices: Restrict Add Ons',
  FEATURE_JOB_FORM_EMAIL_FIELD_REQUIRED: 'Job Form Email Field Required',
  FEATURE_TASK_MANAGEMENT: 'Task Management',
  FEATURE_CUSTOMER_RATES: 'Customer Rates Integration',
  FEATURE_SHOW_CUSTOMER_PAYMENT_INFO: 'Show Customer Payment Info',
  FEATURE_DISABLE_SELF_SERVICE_CUSTOMER_PAYMENTS: 'Disable Self-Service Payments',
  FEATURE_SETTINGS: 'Settings',
  FEATURE_SMS_REQUEST_LOCATION: 'SMS_Request Location',
  FEATURE_SMS_ETA_UPDATES: 'SMS Updates',
  FORCE_REFRESH_COUNTDOWN: window.ENV.FORCE_REFRESH_COUNTDOWN || 360,
  FORCE_REFRESH_RANDOM_WAIT_TIME: window.ENV.FORCE_REFRESH_RANDOM_WAIT_TIME || 60,
  ISSC_REGISTERED_STATUSES: ['registered', 'logged_in', 'logged_out', 'logging_in'],
  MESSAGE_AUTH_ERR: 'An authentication error occured and your last request did not go through',

  MESSAGE_NEED_ONE_DISPATCH_SITE(context: { site_name: string; company_name: string }) {
    return `${context.site_name} is the only digital dispatch site for ${context.company_name}. Please enable an additional digital dispatch site for ${context.company_name} if you'd like to turn off ${context.site_name}.`
  },

  MESSAGE_NEED_SITE_ENABLED: 'Must have at least 1 Site enabled for this account.',
  MESSAGE_SELECT_ISSUE: 'Select an issue and reason to add it to the Job Detail screen.',
  MESSAGE_LOCKED_IN: 'Advise customer to call 911 to ensure the child or pet is out of danger.',
  MESSAGE_AUTOFILL_VIN: 'Valid VIN Autofills Vehicle',
  MESSAGE_INVALID_PHONE: 'Invalid Phone',
  MESSAGE_PARTNER_DISPATCH_NUMBER: 'Partner Dispatch Number',
  MESSAGE_ADD_ADDITIONAL_SITE: 'Add Additional Site',
  MESSAGE_UPDATE_FAILED_TRY_AGAIN: 'Update failed. Please try again.',
  MESSAGE_EDIT(name: string) {
    return `Edit ${name}`
  },

  MESSAGE_CREATE(name: string) {
    return `Create New ${name}`
  },

  COMPANY_SETTINGS: {
    BASE_RESOURCES_URL: 'https://info.agero.com/',
    MANUAL_ENTRY_CODE: 'manual_entry',
    INTERNAL_ERROR_COVERED: 'internal_error_covered',
    POLICY_LOOKUP_SERVICE: {
      FIELDS_REQUIRED_FOR_COVERAGE: 'Policy Lookup Service fields required for Coverage',
      RUN_COVERAGE_ON_NEW_JOB_FORM_LOAD: 'Run Coverage on New Job Form load',
      CUSTOMER_LOOKUP_TYPES: 'Policy Lookup Service lookup types',
    },
    RUN_COVERAGE_MANUAL: {
      RUN_COVERAGE_ON_NEW_JOB_FORM_LOAD: 'Run Coverage on New Job Form load',
    },
    SEARCH_RESULTS_EXCEEDED: 'Search results exceeded',
    POLICY_NUMBER_MIN_LENGTH: 'Policy Number minimum length',
    POLICY_NUMBER_MAX_LENGTH: 'Policy Number maximum length',
  },

  TRANSACTION_PAYMENT: 'Payment',
  TRANSACTION_DISCOUNT: 'Discount',
  TRANSACTION_REFUND: 'Refund',
  TRANSACTION_WRITEOFF: 'Write Off',
  PAYMENT_CANNOT_BE_ZERO: 'Payment cannot be $0.00',
  SYMPTOM_DEAD_BATTERY: 'Dead battery',
  SYMPTOM_LOCKED_OUT: 'Locked out',
  SYMPTOM_FLAT_TIRE: 'Flat tire',
  SYMPTOM_LONG_DISTANCE_TOW: 'Long distance tow',
  SYMPTOM_NEED_A_TOW: 'Need a tow',
  SYMPTOM_OUT_OF_FUEL: 'Out of fuel',
  SYMPTOM_MECHANICAL_ISSUE: 'Mechanical issue',
  SYMPTOM_EV_OUT_OF_CHARGE: 'EV out of charge',
  SYMPTOM_VEHICLE_UNDRIVEABLE: 'Vehicle Undriveable',
  SYMPTOM_STUCK: 'Stuck',
  SYMPTOM_HOME_LOCKOUT: 'Home Lockout',
  SETTTING_DISABLE_EMAIL_UPDATES: 'Disable Email Updates',
  SETTTING_DISABLE_JOB_ALERTS: 'Disable Job Alerts',
  SETTING_DISABLE_NOTIFICATIONS: 'DISABLE_NOTIFICATIONS',

  USER_NOTIFICATION_SETTINGS: {
    DISPATCHED_TO_DRIVER: 'dispatched_to_driver',
  },
  TRY_AGAIN_LATER_RESPONSE: 'Oops! Sorry, but an error has occurred. Please try again later.',
  // user change password
  CHANGE_PASSWORD: {
    INVALID_PASSWORD: 'Oops! Password must be at least 8 characters.',
    PASSWORDS_DONT_MATCH: 'Oops! The passwords must match.',
    PASSWORD_PLACEHOLDER: '8 characters minimum',
    CONFIRMATION_PLACEHOLDER: 'Must match',
  },

  COVERAGE: {
    CLIENT_API_COVERED: 'Client API Covered',
  },
  INTERNAL_ERROR: 'InternalError',
  COVERED_RESULT: 'Covered',
  COVERED: 'Covered',
  MANUAL_ENTRY: 'Manual Entry',
  NOT_COVERED_RESULT: 'NotCovered',
  NOT_COVERED: 'Not Covered',
  PARTIALLY_COVERED_RESULT: 'PartiallyCovered',
  PARTIALLY_COVERED: 'Partially Covered',
  FAILED_RESULT: 'Failed',
  EVENT_LIMIT_REACHED: 'Event Limit Reached',
  CUSTOMER_PAYS_FULL_COST_OF_SERVICE: 'Customer pays full cost of service',
  FLAT_RATE_TOW_DISCOUNT: 'Discounted Price $59.95',
  SERVICE_LOCATION: 'Service Location',
  SERVICE_LOCATION_TYPE: 'Service Location Type',
  DROPOFF_LOCATION: 'Dropoff Location',
  DROPOFF_LOCATION_TYPE: 'Dropoff Location Type',

  // Partner Search
  PARTNER_SEARCH: {
    TITLE: 'Add Partner',
    BUTTON: {
      ADD_PARTNER: 'Add New Partner',
      SELECT_SITE: 'Add',
    },
    NO_RESULTS: 'No results for that dispatch number.',
    CREATE_NEW: 'Click Add New Partner to create a new partner company.',
    ENTER_NUMBER_LONG:
      "Enter the dispatch phone number for the partner you'd like to add.  We'll attempt to pull their details.",
    ENTER_NUMBER: 'Enter the dispatch number to view results',
    SEARCH: 'Search',
    ERROR: {
      BAD_NUMBER: 'Enter full 10-digit phone number to view results',
    },
    SERVER_ERROR: 'Server error',
  },
  ADDITIONAL_SITE: {
    TITLE: 'Add Additional Site',
    BUTTONS: {
      ADD_SITE: 'Add Site',
      CREATE_SITE: 'Create New Site',
    },

    COMPANY_HAS_SITES(company_name: string) {
      return `${company_name} has sites that you have not added yet.  Select a site to add it to your account`
    },
  },
  TYPE_ATTACHED_DOCUMENT: 'AttachedDocument::Document',
  DIGITAL_DISPATCH: {
    RSC: 'rsc',
    ISSC: 'issc',
  },
  JOB: {
    TYPE: {
      RESCUE: 'RescueJob',
      MOTORCLUB: 'FleetMotorClubJob',
      FLEET_MANAGED_JOB: 'FleetManagedJob',
    },

    EDIT_PAYMENT_TYPE:
      'Are you sure you want to edit the Payment Type? The Invoice will be moved to the Partner New tab on the Invoices page to review and approve.',
    MANAGE_STATUS_WARN: 'Are you sure you would like to manage the status for this job?',

    BILLING_TYPE: {
      VCC: 'VCC',
      ACH: 'ACH',
    },
  },
  POI_SITE: {
    HELPER_TEXT:
      'Places are common points of interest that are available as Job Dropoff and Pickup Options for company jobs.',
  },
  COMMISSION_PERCENTAGE: {
    HELPER_TEXT: 'Commission percentage will be used to calculate driver commissions',
    SETTING_TEXT:
      'These settings define how driver commissions will be calculated in the Commissions Report',

    NON_COMMISSIONABLE_MODAL_TEXT(serviceOrAddonItem: string) {
      return `Select the ${serviceOrAddonItem} that should not be included when calculating driver commissions`
    },
  },
  STRIPE_DASHBOARD_URL: window.ENV.STRIPE_DASHBOARD_URL,
  PAYMENT_CALCULATOR_URL: window.ENV.PAYMENT_CALCULATOR_URL,
  STRIPE_BANK_ACCOUNT: {
    LINK: 'Link your bank account in order to receive payments through Swoop',
    FINISH_LINKING: 'Add a bank account to complete your setup',
  },
  SITE: {
    HELPER_TEXT:
      'Sites are company locations that are available as Job Dropoff and Pickup options for company jobs. Users assigned to the Site have visibility into jobs when the Site is selected as a Dropoff location.',
  },
  TAB: {
    DONE: 'done',
    PAID: 'paid',
    SENT: 'sent',
  },
  CUSTOMER_LOOKUP_TYPE_FIELD_OPTIONS: {
    MANUAL: 'Skip and enter manually',
    MEMBER_NUMBER: 'Member Number',
    CLAIM_NUMBER: 'Claim Number',
    FULL_VIN: 'Full Vin',
    NAME_AND_ZIP: 'Last Name & Zip',
    COMPANY_AND_ZIP: 'Company Name & Zip',
    NAME_STATE_AND_ZIP: 'Name, State, and Zip',
    PHONE_NUMBER: 'Phone Number',
    POLICY_NUMBER: 'Policy Number',
    SERVICE_CONTRACT_NUMBER: 'Service Contract Number',
    UNIT_NUMBER: 'Unit Number',
    VIN: 'Last 8 Vin',
    NAME_AND_PHONE: 'Last Name & Phone',
    SOCIAL_SECURITY_NUMBER: 'Social Security Number',
    NAME_PHONE_VEHICLE: 'Name, Phone, Vehicle',
    NAME_ADDRESS_VEHICLE: 'Name, Address, Vehicle',
  },
  LOADING: 'loading...',
  USER_SETTING: {
    FILTERED_DISPATCHERS: 'filtered_dispatchers',
    FILTERED_STATES: 'filtered_states',
    FILTERED_FLEET_COMPANIES_FOR_JOBS: 'filtered_fleet_companies_for_jobs',
    FILTERED_FLEET_COMPANIES_FOR_REVIEWS: 'filtered_fleet_companies_for_reviews',
    FILTERED_DEPARTMENTS: 'filtered_departments',
    FILTERED_DISPATCH_SITES: 'filtered_dispatch_sites',
    FILTERED_FLEET_SITES: 'filtered_fleet_sites',
    FILTERED_SERVICES: 'filtered_services',
    FILTERED_INVOICE_STATUS: 'filtered_invoice_status',
    FILTERED_REVIEWS: 'filtered_reviews',
    FILTERED_STATES_TASKS: 'filtered_states_tasks',
    FILTERED_SERVICES_TASKS: 'filtered_services_tasks',
    FILTERED_DISPATCHERS_TASKS: 'filtered_dispatchers_tasks',
    FILTERED_FLEET_COMPANIES_TASKS: 'filtered_fleet_companies_tasks',
    ACTIVE_TAB: 'active_tab',
    DONE_TAB: 'done_tab',
    LAST_SITE_ID_ON_JOB_FORM: 'last_site_id_on_job_form',
    INVOICES_SORTBY: 'invoices_sortby',
    DONE_JOBS_SORTBY: 'doneJobs_sortby',
    COMPANIES_SORTBY: 'companies_sortby',
    USERS_SORTBY: 'users_sortby',
    PARTNER_SORTBY: 'partners_sortby',
    ACCOUNT_SORTBY: 'accounts_sortby',
    SHOW_OFF_DUTY_ASSIGN_DRIVER_MAP: 'show_off_duty_assign_driver_map',
    SHOW_OFF_DUTY_PARTNER_FLEET_MAP: 'show_off_duty_partner_fleet_map',
    SHOW_ROUTES_PARTNER_FLEET_MAP: 'show_routes_partner_fleet_map',
    SHOW_WITHOUT_DRIVERS_PARTNER_FLEET_MAP: 'show_without_drivers_partner_fleet_map',
  },
  SITES_AND_PLACES_LOAD_THRESHOLD: 100,
  US_GEOGRAPHIC_CENTER: {
    lat: 39.8283,
    lng: -98.5795,
  },
  CMW_REQUEST_LOCATION_TIMEOUT: 10000,
  GENERIC_MARKER_PATH:
    'M19,11.8894533 C19,10.2253484 18.4140625,8.80457971 17.2421875,7.62714706 C16.0703125,6.44971441 14.65625,5.86099809 13,5.86099809 C11.34375,5.86099809 9.9296875,6.44971441 8.7578125,7.62714706 C7.5859375,8.80457971 7,10.2253484 7,11.8894533 C7,13.5535581 7.5859375,14.9743268 8.7578125,16.1517595 C9.9296875,17.3291921 11.34375,17.9179084 13,17.9179084 C14.65625,17.9179084 16.0703125,17.3291921 17.2421875,16.1517595 C18.4140625,14.9743268 19,13.5535581 19,11.8894533 Z M26,11.7219962 C26,19.4141201 13.609375,37 13,37 C12.390625,37 0,19.4141201 0,11.7219962 C1.39228677e-16,8.48623681 1.26953125,5.72363094 3.80859375,3.43417857 C6.34765625,1.14472619 9.41145833,0 13,0 C16.5885417,0 19.6523438,1.14472619 22.1914063,3.43417857 C24.7304688,5.72363094 26,8.48623681 26,11.7219962 Z',
  USER_STORE: {
    RECEIVED_USER: 'receivedUser',
    RECEIVED_API_KEY: 'receivedApiKey',
    RECEIVED_LOGIN: 'receivedLoginInfo',
    CHANGE: 'change',
    USERME_CHANGED: 'user_me_changed',
    LOGIN: 'login',
    LOGOUT: 'logout',
    READY: 'ready',
    PASSWORD_REQUEST_IS_NOT_VALID: 'passwordRequestIsNotValid',
    CHANGE_PASSWORD: 'changePassword',
    INVALID_PASSWORD: 'invalidPassword',
    CHANGE_PASSWORD_FAIL: 'changePasswordFail',
    PHONE_LOGOUT_FAIL: 'phoneLogoutFail',
  },

  WINBACK_STATUS: {
    RESOLVED: 'Resolved',
    NO_RESPONSE: 'No Response',
    UNRESOLVED: 'Unresolved',
    NO_ACTION_REQUIRED: 'No Action Required',
  },

  CUSTOMER_PAYMENT_TYPE: {
    CREDIT_CARD: 'Credit Card',
    CREDIT_CARD_VIA_PHONE: 'Credit Card via Phone',
    CASH: 'Cash to Service Provider',
    TEXT_PAYMENT_LINK: 'Text Payment Link',
    TRANSFER_TO_SUPER_COMPANY: 'Transfer to',
    MANUALLY_AUTHORIZED: 'Client / Supervisor Authorization',
  },

  AUTHORIZATION_FORM_TYPE_LABELS: {
    CLIENT: 'Client',
    SUPERVISOR: 'Supervisor',
  },

  VEHICLE_DRIVER_TYPE: {
    DRIVER: 'Driver',
    PASSENGER: 'Passenger',
  },

  CONFIRM_CALLBACK_NUMBER: 'Please confirm callback number with customer before creating job.',

  SCREEN_POPS_BASIC_TYPES: {
    ROBOCALL: 'robocall',
    TOO_MANY_JOBS: 'too many jobs',
    MULTIPLE_JOBS: 'multiple jobs',
    NO_JOBS: 'no jobs',
    ACTIVE_INCOMING_JOB: 'active incoming job',
  },
  AGENT_ALERT_TYPE: {
    OUT_OF_NETWORK_DISPATCH_NEEDED: 'out of network dispatch needed',
    ETA_EXPIRED: 'eta expired',
    NOT_COMPLETE: 'not complete',
    NOT_ON_SITE: 'not on-site',
    PROVIDER_GOA_REQUEST: 'provider goa request',
    MANUAL_DISPATCH: 'manual dispatch',
    CUSTOMER_PAYMENT_REQUIRED: 'customer payment required',
    PROVIDER_UNSUCCESSFUL_SERVICE_REQUEST: 'provider unsuccessful request',
    MISSING_INFO: 'missing information required',
    PROVIDER_REASSIGN_REQUEST: 'provider reassign request',
    SERVICE_RECOVERY: 'service recovery',
    RELEASE_REQUIRED: 'release required',
    COMPLETE_CONFIRMATION: 'complete confirmation',
    TOW_OUT_REQUIRED: 'tow out required',
  },
  JOB_TERMINATION_REASONS(managerCompanyName?: string | null) {
    return {
      CUSTOMER_CANCELING: 'CustomerCanceling',
      CUSTOMER_CANCELING_DESCRIPTION: 'Customer Canceling - Customer called to cancel',
      DISPATCHER_CANCELING: 'DispatcherCanceling',
      DISPATCHER_CANCELING_DESCRIPTION: `${managerCompanyName} Dispatcher Canceling - ${managerCompanyName} Dispatch deciding to reassign job`,
      GONE_ON_ARRIVAL: 'GoneOnArrival',
      GONE_ON_ARRIVAL_DESCRIPTION: 'Gone On Arrival (GOA) - Customer not on site',
      PROVIDER_CANCELING: 'ProviderCanceling',
      PROVIDER_CANCELING_DESCRIPTION: 'Provider Canceling - Provider needs to cancel',
      SERVICE_UNSUCCESSFUL: 'ServiceUnsuccessful',
      SERVICE_UNSUCCESSFUL_DESCRIPTION: 'Service Unsuccessful - Customer needs a different service',
    }
  },
  GEOCODING_VALIDATION_MESSAGE: {
    VALUE_WHEN_MAP_PRESENT: 'Select address from dropdown or drop pin on map',
    VALUE_WHEN_ONLY_DROPDOWN: 'Select address from dropdown',
    DEFAULT_VALUE: 'Address Not Set',
  },
  SYSTEM_PROGRAMS: ['Downtime', 'Internal Error', 'Manual Entry'],
  SYSTEM_PROGRAM_CODES: ['internal_error_covered', 'manual_entry', 'downtime'],
  PAYOUT_INTERVAL: [
    {
      id: 'daily',
      name: 'Daily',
    },
    {
      id: 'weekly',
      name: 'Weekly (Friday)',
    },
    {
      id: 'monthly',
      name: 'Monthly (1st of Month)',
    },
  ],
  SUBSCRIPTION_STATUS: {
    1: 'Network Only',
    2: 'Free Trial',
    3: 'Subscribed - Essential',
    4: 'Subscribed - Premium',
    5: 'Subscribed - Professional',
    6: 'Subscribed - Enterprise',
    7: 'Subscribed - Free',
    8: 'Subscribed - Custom',
    9: 'Inactive',
    10: 'Pending Cancelation',
  },
  STANDARD_TIMEZONES: [
    'US/Alaska',
    'US/Arizona',
    'US/Central',
    'US/Eastern',
    'US/Hawaii',
    'US/Mountain',
    'US/Pacific',
  ],
  TIMEZONE_NAMES: {
    EST: 'US/Eastern Standard Time',
    EDT: 'US/Eastern Daylight Time',
    CST: 'US/Central Standard Time',
    CDT: 'US/Central Daylight Time',
    MST: 'US/Mountain Standard Time',
    MDT: 'US/Mountain Daylight Time',
    PST: 'US/Pacific Standard Time',
    PDT: 'US/Pacific Daylight Time',
    AKST: 'US/Alaska Standard Time',
    AKDT: 'US/Alaska Daylight Time',
    HST: 'US/Hawaii Standard Time',
    HDT: 'US/Hawaii Daylight Time',
  } as Record<string, string>,
  SEARCH_TYPE: {
    loading: 'loading',
    retry: 'retry',
    select: 'select',
    internalError: 'internalError',
    externalError: 'externalError',
    authorizationError: 'authorizationError',
    partialContent: 'partialContent',
  },
  WS_TIMEOUT_ERROR_MESSAGE: 'Timeout',
  WS_EMPTY_ERROR_MESSAGE: 'Empty Response',
  WEB_LOCATION_RESOLUTION_TOPIC: 'WebLocationResolution',
  MOBILE_LOCATION_RESOLUTION_TOPIC: 'MobileLocationResolution',
  GEOCODING_COMPONENT: 'Geocoding',
} as const

const PARTNER_INVOICE_SENT_STATES = [
  Consts.IPARTNERSENT,
  Consts.IFLEETAPPROVED,
  Consts.IFLEETDOWNLOADED,
  Consts.IPARTNEROFFLINENEW,
  Consts.ISWOOPNEW,
  Consts.ISWOOPDOWNLOADEDPARTNER,
  Consts.ISWOOPAPPROVEDPARTNER,
] as const

const INVOICE_UPDATE_ELIGIBILITY_STATES = [
  Consts.IPARTNERNEW,
  Consts.ISWOOPREJECTEDPARTNER,
] as const

const DependentConsts = {
  INVOICE_UPDATE_ELIGIBILITY_STATES,
  PARTNER_INVOICE_SENT_STATES,
  PARTNER_INVOICE_APPROVED_AND_SENT_STATES: [
    Consts.IPARTNERAPPROVED,
    ...PARTNER_INVOICE_SENT_STATES,
  ],
  TRANSACTIONS: [
    Consts.TRANSACTION_PAYMENT,
    Consts.TRANSACTION_DISCOUNT,
    Consts.TRANSACTION_REFUND,
    Consts.TRANSACTION_WRITEOFF,
  ],
  TRANSACTIONS_NEGATIVE: [Consts.TRANSACTION_REFUND],
  TRANSACTIONS_WITH_METHOD: [Consts.TRANSACTION_PAYMENT, Consts.TRANSACTION_REFUND],
  TESLA_ADDITIONS: [
    Consts.ADDITION_OVERNIGHT_STORAGE,
    Consts.ADDITION_TOLL,
    Consts.ADDITION_PRIORITY_RESPONSE,
    Consts.ADDITION_LOANER_WHEEL,
  ],
  DISABLED_MOTORCLUBS: [Consts.GEICO, Consts.ALLSTATE, Consts.ALLIED, Consts.ROADAMERICA],
  FLEETS: [
    Consts.SWOOP,
    Consts.TESLA,
    'FlightCar',
    Consts.FARMERS,
    Consts.CENTURY,
    'Silvercar',
    'SpoonRocket',
    'Getaround',
    Consts.METROMILE,
  ],
  ORDERED_STATUSES: [
    Consts.CREATED,
    Consts.CANCELED,
    Consts.GOA,
    Consts.DRAFT,
    Consts.PENDING,
    Consts.REJECTED,
    Consts.UNASSIGNED,
    Consts.ETAREJECTED,
    Consts.REASSIGNED,
    Consts.REASSIGN,
    Consts.AUTO_ASSIGNING,
    Consts.ASSIGNED,
    Consts.EXPIRED,
    Consts.SUBMITTED,
    Consts.ACCEPTED,
    Consts.DISPATCHED,
    Consts.ENROUTE,
    Consts.ONSITE,
    Consts.TOWING,
    Consts.TOWDESTINATION,
    Consts.STORED,
    Consts.RELEASED,
    Consts.PROVIDER_RELEASED,
    Consts.COMPLETED,
  ],
  ACTIVE_STATUSES: [
    Consts.CREATED,
    Consts.DRAFT,
    Consts.PENDING,
    Consts.UNASSIGNED,
    Consts.ASSIGNED,
    Consts.AUTO_ASSIGNING,
    Consts.ACCEPTED,
    Consts.SUBMITTED,
    Consts.REASSIGN,
    Consts.DISPATCHED,
    Consts.ENROUTE,
    Consts.ONSITE,
    Consts.TOWING,
    Consts.TOWDESTINATION,
    Consts.RELEASED,
  ],
  NON_PARTNER_DISPATCHED_STATUSES: [
    Consts.ACCEPTED,
    Consts.DISPATCHED,
    Consts.ENROUTE,
    Consts.ONSITE,
    Consts.TOWING,
    Consts.TOWDESTINATION,
  ],
  DISPATCHED_STATUSES: [
    Consts.DISPATCHED,
    Consts.ENROUTE,
    Consts.ONSITE,
    Consts.TOWING,
    Consts.TOWDESTINATION,
  ],
  NON_PARTNER_PENDING_STATUSES: [
    Consts.CREATED,
    Consts.DRAFT,
    Consts.PENDING,
    Consts.UNASSIGNED,
    Consts.ASSIGNED,
    Consts.AUTO_ASSIGNING,
    Consts.SUBMITTED,
    Consts.REASSIGN,
  ],
  COMPLETED_STATUSES: [
    Consts.GOA,
    Consts.EXPIRED,
    Consts.COMPLETED,
    Consts.CANCELED,
    Consts.STORED,
    Consts.PROVIDER_RELEASED,
    Consts.REJECTED,
    Consts.REASSIGNED,
    Consts.UNSUCCESSFUL,
    Consts.DELETED,
  ],
  SCREEN_POP_STATUSES: {
    DRAFT: Consts.DRAFT,
    PENDING: Consts.PENDING,
    SCHEDULED: Consts.SCHEDULED,
  },
  TOW_SERVICES: [
    Consts.ACCIDENT_TOW,
    Consts.TOW,
    Consts.SECONDARYTOW,
    Consts.TRANSPORT,
    Consts.RECOVERY,
    Consts.ACCIDENT_TOW_P,
  ],
} as const

const AllConsts = { ...Consts, ...DependentConsts }

export default AllConsts
